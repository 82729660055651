import React from 'react';
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { useState } from 'react';
import './App.css';

function App() {
  const [data, setData] = useState("Not Found");
  return (
      <>
        <BarcodeScannerComponent
            width={500}
            height={500}
            onUpdate={(err, result) => {
              if (result) setData(result.toString());
              else setData("Not Found");
              console.log('update');
            }}
        />
        <p>{data}</p>
      </>
  );
}

export default App;
